import React from "react";
import { Nav, Button, Row, Col, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "../../styles_custom/PageNotFound.css";

const PageNotFound = () => {
  return (
    <>
      <Row style={{ textAlign: "center" }}>
        <Col md={12}>
          <h1>404</h1>
          <Image
            src="https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif"
            alt="404 image"
            fluid
            rounded
            style={{ height: "400px" }}
          ></Image>
          <h3 className="h2">Looks like you're lost</h3>
          <p>the page you are looking for is not avaible!</p>
          <br />
          <LinkContainer to="/">
            <Nav.Link>
              <Button type="button" className="btn-block">
                Go to home
              </Button>
            </Nav.Link>
          </LinkContainer>
        </Col>
      </Row>
    </>
  );
};

export default PageNotFound;
