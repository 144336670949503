import React from "react";
import data from "../../data/index.json";
import GithubButton from "./GithubButton";
import LinkSvgButton from "./LinkSvgButton";

const MyPortfolio = () => {
  return (
    <section className="portfolio--section" id="MyPortfolio">
      <div className="portfolio--container-box">
        <div className="portfolio--container">
          <p className="sub--title">Recent Projects</p>
          <h1 className="section--heading">My Portfolio</h1>
        </div>
        <div>
          <a
            href="https://github.com/aayush6696"
            target="_blank"
            rel="noreferrer"
            className="btn btn-github"
          >
            <GithubButton />
            Visit My Github
          </a>
        </div>
      </div>
      <div className="portfolio--section--container">
        {data?.portfolio?.map((item, index) => {
          return (
            <div className="portfolio--section--card" key={index}>
              <div className="portfolio--section--img">
                <img src={item.src} alt="portfolio" />
              </div>
              <div className="portfolio--section--card--content">
                <div>
                  <h3 className="portfolio--section--title">{item.title}</h3>
                  <p className="text-md">{item.description}</p>
                </div>
                <a
                  href={item.link}
                  className="text-sm portfolio--link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkSvgButton />
                  View in Github
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default MyPortfolio;
