import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    toast.success(
      "Thank you for contacting me. I will get back to you shortly",
      {
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        position: toast.POSITION.BOTTOM_CENTER,
      }
    );
    e.preventDefault();

    emailjs
      .sendForm(
        "service_fv66rwk",
        "template_qbvwsoa",
        form.current,
        "Id7kYBR04leIdAmVX"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  return (
    <section className="contact--section" id="contactMe">
      <div>
        <p className="sub--title">Get in touch</p>
        <h1>Contact me</h1>
        <p className="text-lg">
          Get in touch with me. I usually reply within a day.
        </p>
      </div>
      <form
        onSubmit={sendEmail}
        ref={form}
        className="contact--form--container"
      >
        <div className="container">
          <label htmlFor="first_name" className="contact--label">
            <span className="text-md">First Name*</span>
            <input
              type="text"
              className="contact--input text-md"
              name="first_name"
              id="first_name"
              required
            ></input>
          </label>
          <label htmlFor="last_name" className="contact--label">
            <span className="text-md">Last Name*</span>
            <input
              type="text"
              className="contact--input text-md"
              name="last_name"
              id="last_name"
              required
            ></input>
          </label>
          <label htmlFor="email" className="contact--label">
            <span className="text-md">Email*</span>
            <input
              type="email"
              className="contact--input text-md"
              name="email"
              id="email"
              required
            ></input>
          </label>
          <label htmlFor="phone" className="contact--label">
            <span className="text-md">Phone Number*</span>
            <input
              type="number"
              className="contact--input text-md"
              name="phone"
              id="phone"
              required
            ></input>
          </label>
        </div>
        <label htmlFor="subject" className="contact--label">
          <span className="text-md">Subject*</span>
          <input
            type="text"
            className="contact--input text-md"
            name="subject"
            id="subject"
            required
          ></input>
        </label>
        <label htmlFor="message" className="contact--label">
          <span className="text-md">Message</span>
          <textarea
            className="contact--input text-md"
            name="message"
            id="message"
            rows={8}
            placeholder="Type your message...."
          ></textarea>
        </label>
        <label htmlFor="checkbox" className="checkbox--label">
          <input type="checkbox" required name="checkbox" id="checkbox" />
          <span style={{textAlign:"left"}} className="text-sm">If you submit a question via the contact form, I need to process your data in order to respond. However, your data will only be processed for this purpose, and will not be used for anything else.</span>
        </label>
        <div>
          <button className="btn btn-primary contact--form--btn">Submit</button>
        </div>
      </form>
      <ToastContainer />
    </section>
  );
};

export default Contact;
