import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homescreen from "./Pages/Home/Homescreen/Homescreen";
import Navbar from "./Pages/Home/Navbar";
import PageNotFound from "./Pages/Home/PageNotFound";
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <Router>
        <div>
          <Navbar />
          <Routes>
            <Route path="/" element={<Homescreen />}></Route>
            <Route path="*" element={<PageNotFound />}></Route>
          </Routes>
        </div>
      </Router>
    </div>
  );
};

export default App;
