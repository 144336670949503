import React from "react";
import "../../styles_custom/Timeline.css";
import data from "../../data/index.json";

const Experience = () => {
  return (
    <>
      <section className="portfolio--section">
        <div className="portfolio--container-box">
          <div className="portfolio--container">
            <h1 className="section--heading">Education</h1>
          </div>
        </div>
      </section>
      <div style={{ padding: "10px" }} class="boxesContainer">
        {data?.education?.map((item, index) => {
          return (
            <div class="cardBox">
              <div class="card">
                <div class="front">
                  <h3 style={{ marginTop: "17.5%" }}>{item.title}</h3>
                  <strong>&#x21bb;</strong>
                </div>
                <div class="back">
                  <h3 style={{ marginTop: "7.5%", color: "white" }}>
                    {item.school}
                  </h3>
                  <p style={{ marginTop: "3%", color: "white" }}>
                    {item.degree}
                    <br />
                    {item.year}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Experience;
