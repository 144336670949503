import React from "react";

const About = () => {
  const style = {
    textAlign: "left",
  };
  return (
    <section className="about--section" id="AboutMe">
      <div className="about--section--image">
        <img
          loading="lazy"
          src="/img/about-me-image.webp"
          alt="about me"
          style={{ width: "100%" }}
        />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <p className="section--title">About</p>
          <h1 className="skills-section--heading">About Me</h1>
          <p className="hero--section-description" style={style}>
            Currently working as a web developer seeking to obtain a responsible
            position and work with full determination and dedication in an
            organization that utilizes my skills and offers consistent growth
            with a platform to enrich my talent.
          </p>
          <p className="hero--section-description" style={style}>
            Able to effectively self-manage during independent projects, as well
            as collaborate in a team setting.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
