import React from "react";
import HeroSection from "../HeroSection";
import MySkills from "../MySkills";
import About from "../About";
import MyPortfolio from "../MyPortfolio";
import Contact from "../Contact";
import Footer from "../Footer";
import Experience from "../Experience";

const Homescreen = () => {
  return (
    <>
      <HeroSection />
      <MySkills />
      <About />
      <Experience />
      <MyPortfolio />
      <Contact />
      <Footer />
    </>
  );
};

export default Homescreen;
