import React from "react";
import { Link } from "react-scroll";

const HeroSection = () => {
  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
          <p className="section--title">Hi!, I am Aayush</p>
          <h1 className="hero--section-title">
            <span className="hero--section--title--color">Full Stack</span>{" "}
            <br />
            Developer
          </h1>
          <p className="hero--section--description">
            Working as a Web developer with React.js as frontend
            <br /> and C# as backend technologies using Sitecore CMS.
          </p>
          <br />
        </div>
        <Link
          activeClass="navbar--active-content"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          to="contactMe"
          className="btn btn-primary"
        >
          Get in touch
        </Link>
      </div>
      <div className="hero--section--img">
        <img src="/img/hero-banner-image.webp" alt="hero_section"></img>
      </div>
    </section>
  );
};

export default HeroSection;
